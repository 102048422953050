import React from 'react';
import Collapsible from 'react-collapsible';
import ChevronIcon from '../assets/arrow.svg';

import SanityPortableText from "./SanityPortableText";

import './Faq.scss';

const transitionTime = 200;

function Question({ children, title, }) {

  return (
    <Collapsible
      className="Question"
      openedClassName="Question QuestionOpen"
      trigger={
        <div className="layout horizontal center">
          {title}
          <div className="flex-auto" />
          <ChevronIcon className="ChevronIcon" />
        </div>
      }
      transitionTime={transitionTime}
    >
      { children }
    </Collapsible>
  );
}

function Faq({questions}) {
  return (
    <div className="Faq">
      {questions?.map(question => (
        <Question title={question.question}>
          <SanityPortableText blocks={question._rawAnswer} />
        </Question>
      ))}
    </div>
  );
}

export default Faq;